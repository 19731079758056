export const Mode = {
  WebCatalog: 'web-catalog',
  Chat: 'chat',
} as const

export type ModeType = (typeof Mode)[keyof typeof Mode]

export function getModeFromUrl(url: string): ModeType {
  const urlObject = new URL(url)
  const pathParts = urlObject.pathname.split('/').filter(Boolean)
  const mode = (pathParts[0] || Mode.WebCatalog) as ModeType

  if (Object.values(Mode).includes(mode)) {
    return mode as ModeType
  } else {
    return Mode.WebCatalog
  }
}

export function extractBrandIdFromUrl(
  url: string,
  host: string = window.location.origin
): number | null {
  const regex = new RegExp(`^${host}/brands/(\\d+)(?:\\?.*)?$`)
  const match = url.match(regex)

  if (match && match[1]) {
    return parseInt(match[1], 10)
  }

  return null
}

export function extractBrandIdFromPath(url: string): number | null {
  const regex = /^\/brands\/(\d+)(?:\?.*)?$/
  const match = url.match(regex)

  if (match && match[1]) {
    return parseInt(match[1], 10)
  }

  return null
}
