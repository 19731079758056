import { extractBrandIdFromPath } from '#imports'

export default defineNuxtRouteMiddleware((to, from) => {
  const browserLang = navigator.language || navigator.languages[0]
  // 日本語設定なら何もしない
  if (browserLang.startsWith('ja')) {
    return
  }

  // 日本語以外で/chatでなければ/chatにリダイレクト
  if (!to.fullPath.startsWith('/chat')) {
    // ブランドURLだった場合、ブランドIDを取得する。
    const brandId = extractBrandIdFromPath(from.path)

    return navigateTo({
      path: '/chat',
      query: {
        ...(brandId && { brandId }),
      },
    })
  }
})
